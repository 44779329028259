<template lang='pug'>
  extends ../templates/PageBase.pug
  block content
    .container-fluid.container-fixed-lg
      .row
        .col-lg-6
          .card.card-default.card-input
            .card-header
              .card-title
                | Password recipe
            .card-body
              h5
                | Drag to setup password recipe
              form(role='form')
                .form-group.required
                  label Password length
                  .irs-wrapper
                    input.ion_slider(type='text' ref='inputPasswordLength')
                .form-group
                  label Digits
                  span.help &nbsp; e.g. 012
                  .irs-wrapper
                    input.ion_slider(type='text' ref='inputPasswordNumbers')
                .form-group
                  label Special chars
                  span.help &nbsp; e.g. !#$%&()
                  input.ion_slider(type='text' ref='inputPasswordSymbols')

                .form-group
                  label Easy to read
                  span.help &nbsp;Ignores similar Characters e.g. l,i,1
                  br
                  input.form-control.password-render(
                    type='checkbox'
                    ref='easyRead'
                    data-size='small'
                    data-color='primary'
                  )
              .form-group
                br
                br
                button.btn.btn-lg.btn-larger.btn-primary.btn-cons(@click='generatePasswords') Generate new passwords

        .col-lg-6
          .card.card-default
            .card-header
              .card-title Your Passwords are&nbsp;
                b {{ passwordIndicator.scoreText }}
            .card-body
              h5 A fast Desktop needs&nbsp
                b {{ passwordStrength }}
                | &nbsp;to bruteforce
              .progress
                .progress-bar(:style='{width: passwordIndicator.width + "%"}' :class='passwordIndicator.cls')
              form(role='form')
                .input-group.m-b-5(v-for='pw in generatedPasswords')
                  input.form-control.input-lg.password-render(type='text' :value='pw')
                  .input-group-append
                    span.input-group-text.primary.copy-button(@click='copy')
                      i.fa.fa-copy

    .container-fluid.container-fixed-lg.bg-white
      .card.card-transparent
        .card-header
          .card-title
            | Why using this Password generator?
        .card-body
          .row
            .col-lg-6
              card(
                icon='💪'
                :icon-pos-left='false'
                title='Generate strong Passwords'
                description="Customize the Strength of your Passwords. Generate Passwords for WLAN, accounts or Server."
              )
            .col-lg-6
              card(
                icon='🔒'
                :icon-pos-left='false'
                title='Absolutely safe'
                description="Passwords are generated on your Browser and not sending through the internet. We don't save or log any Passwords."
              )
    //.container-fluid.container-fixed-lg
      .row
        .col-lg-12
          .card.card-transparent
            .card-header
              .card-title
                | Do i have to remember all these passwords?
            .card-body
              h3.text-primary.no-margin No - you don't!
              br
              p We are very bad in memorizing random characters and numbers, especially when there is a lot of them for example for each account or server.
              p This is why im personally using Password-Managers. If you let me - i'll recommend you the tools im using for years and some of the top paid and free Password-Managers.

    //.container-fluid.container-fixed-lg.bg-white
      .card.card-transparent
        .card-header
          .card-title
            | Commercial Password Managers
        .card-body
          .row
            .col-lg-6(v-for='pm in commercialManagers')
              card(:icon='pm.icon' :icon-pos-left='true' :title='pm.title' :description='pm.description')

    //.container-fluid.container-fixed-lg
      .card.card-transparent
        .card-header
          .card-title
            | Free Password Managers
        .card-body
          .row
            .col-lg-6(v-for='pm in freeManagers')
              card(:icon='pm.icon' :icon-pos-left='true' :title='pm.title' :description='pm.description')
</template>

<script>
  import '../assets/plugins/switchery/js/switchery'
  import '../assets/plugins/ion-slider/js/ion.rangeSlider.min'
  import Vue from 'vue'
  import Card from '../components/Card'
  let zxcvbn = require('zxcvbn')

  const alphabet = {
    upper: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    lower: 'abcdefghijklmnopqrstuvwxyz',
    digits: '0123456789',
    symbols: '!#$%&()*+,-./:;<=>?@[]^_{¦}~',
    similar: 'b6cgq9C(G6LlI1|O0S5VUZ2'
  }

  const commercialManagers = [
    {title: '1Password', icon: '🔒', description: 'a cool description'},
    {title: 'Lastpass', icon: '🔒', description: 'a cool description'},
    {title: 'Dashlane', icon: '🔒', description: 'a cool description'},
    {title: 'RoboForm', icon: '🔒', description: 'a cool description'}
  ]

  const freeManagers = [
    {title: '1Password', icon: '🔒', description: 'a cool description'},
    {title: 'Lastpass', icon: '🔒', description: 'a cool description'},
    {title: 'Dashlane', icon: '🔒', description: 'a cool description'},
    {title: 'RoboForm', icon: '🔒', description: 'a cool description'}
  ]

  export default {
    name: 'PasswordGenerator',
    components: {Card},
    mounted() {
      const self = this
      new window.Switchery(this.$refs.easyRead, {color: '#6d5cae', size: 'small'})

      this.$refs.easyRead.onchange = function () {
        Vue.set(self, 'easyRead', self.$refs.easyRead.checked)
      }

      window
        .$(this.$refs.inputPasswordLength)
        .ionRangeSlider({
          min: 5,
          max: 64,
          from: 10,
          type: 'single',
          grid: true,
          onChange(data) {
            self.passwordLength = data.from
          }
        })

      window
        .$(this.$refs.inputPasswordNumbers)
        .ionRangeSlider({
          min: 0,
          max: 10,
          from: 0,
          type: 'single',
          grid: true,
          onChange(data) {
            self.passwordDigits = data.from
          }
        })

      window
        .$(this.$refs.inputPasswordSymbols)
        .ionRangeSlider({
          min: 0,
          max: 10,
          from: 0,
          type: 'single',
          grid: true,
          onChange(data) {
            self.passwordSymbols = data.from
          }
        })
    },
    data() {
      return {
        passwordLength: 10,
        passwordDigits: 0,
        passwordSymbols: 0,
        allowUpperCase: true,
        allowLowerCase: true,
        easyRead: false,
        forceCreate: false,
        numPassword: 9,
        commercialManagers: commercialManagers,
        freeManagers: freeManagers
      }
    },
    computed: {
      passwordIndicator() {
        const pass = zxcvbn(this.generatedPasswords[0])
        const mapText = ['too weak', 'weak', 'medium', 'strong', 'very strong']
        const mapProgress = ['', 'progress-bar-danger', 'progress-bar-warning', 'progress-bar-complete', 'progress-bar-success']
        return {width: (100 / 4) * pass.score, cls: mapProgress[pass.score], scoreText: mapText[pass.score]}
      },
      passwordStrength() {
        const pass = zxcvbn(this.generatedPasswords[0])

        return pass.crack_times_display.offline_slow_hashing_1e4_per_second
      },
      generatedPasswords() {
        const length = this.passwordLength
        let passwords = []
        const re = RegExp(`[${alphabet.similar}]`, 'g')
        const digits = this.easyRead ? alphabet.digits.replace(re, '') : alphabet.digits
        const symbols = this.easyRead ? alphabet.symbols.replace(re, '') : alphabet.symbols
        this.forceCreate

        let desiredAlpha = alphabet.upper + alphabet.lower

        if (this.easyRead) {
          desiredAlpha = desiredAlpha.replace(re, '')
        }

        for (let numPasswords = 0; numPasswords < this.numPassword; numPasswords++) {
          let pw = []
          for (let i = 0; i < length; i++) {
            const randChar = desiredAlpha[Math.floor(Math.random() * desiredAlpha.length)]
            pw.push(randChar)
          }

          // render symbols
          while (pw.filter(i => symbols.includes(i)).length < Math.min(this.passwordSymbols, length)) {
            const pos = Math.floor(Math.random() * length)
            const cha = symbols[Math.floor(Math.random() * symbols.length)]
            pw[pos] = cha
          }

          // render digits
          while (pw.filter(i => parseInt(i)).length < Math.min(this.passwordDigits, length)) {
            const pos = Math.floor(Math.random() * length)
            const cha = digits[Math.floor(Math.random() * digits.length)]
            pw[pos] = cha
          }
          passwords.push(pw.join(''))
        }

        return passwords
      }
    },
    methods: {
      generatePasswords() {
        this.forceCreate = !this.forceCreate
      },
      copy(ele) {
        const input = ele.target.parentElement.parentElement.parentElement.querySelector('input')
        input.select()
        document.execCommand("copy");

        this.$parent.$emit('pgNotification', {message: 'Successfully copied to clipboard!', type: 'success'})
      }
    }
  }
</script>

<style scoped lang='scss'>
  @import "../assets/plugins/switchery/css/switchery.css";
  @import "../assets/plugins/ion-slider/css/ion.rangeSlider.css";
  @import "../assets/plugins/ion-slider/css/ion.rangeSlider.skinFlat.css";

  .password-render {
    font-family: "Courier New";
  }

  .card-input {
    height: 589px;
  }

  .copy-button {
    cursor: pointer;

    .fa-copy {
      color: white;
    }
  }

</style>
