<template lang='pug'>
.card.card-default
  .card-body.no-padding
    .container-sm-height
      .row.row-sm-height
        template(v-if='iconPosLeft')
          .col-md-3.justify-content-center.d-flex.flex-column.bg-master-lighter
            h4.text-center.text-primary.no-margin.emoji
              | {{ icon }}
          .col-md-9.col-sm-height.padding-20.col-top
            p.font-montserrat.bold {{ title }}
            p.small.hint-text {{ description }}

        template(v-if='!iconPosLeft')
          .col-md-9.col-sm-height.padding-20.col-top
            p.font-montserrat.bold {{ title }}
            p.small.hint-text {{ description }}
          .col-md-3.justify-content-center.d-flex.flex-column.bg-master-lighter
            h4.text-center.text-primary.no-margin.emoji
              | {{ icon }}

</template>

<script>
export default {
  name: 'Card',
  props: {icon: {}, title: {}, description: {}, iconPosLeft: {default: true}}
}
</script>

<style scoped>
.emoji {
  font-size: 40px;
}
</style>
